<template>
  <div :class="baseClass + '-mobile-info-page'">
    <div :class="baseClass + '-mobile-info-page-content'">
      <img
        :src="'/static' + info.img"
        :class="baseClass + '-mobile-info-page-content-banner'"
      />
      <div :class="baseClass + '-mobile-info-page-content-info'">
        <div :class="baseClass + '-mobile-info-page-content-info-title'">
          {{ info.name }}
        </div>
        <div :class="baseClass + '-mobile-info-page-content-info-dec'">
          {{ info.description }}
        </div>
        <div :class="baseClass + '-mobile-info-page-content-info-btn'" @click="$router.push('/play/'+$route.params.id)">
          Play Now
        </div>
      </div>
    </div>
    <div :class="baseClass + '-mobile-info-page-ads'"></div>
    <AllGames />
  </div>
</template>
<script>
import AllGames from "@/components/MobileAllGames.vue";
export default {
  components: { AllGames },
  data() {
    return {
      games: [],
      info: {},
    };
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const id = this.$route.params.id;
      if (id) {
        this.$store.dispatch("getGameInfo", {
          id,
          callback: (game) => {
            this.info = game;
          },
        });
      }
      this.$store.dispatch("randomData", {
        num: 12,
        callback: (games) => {
          this.games = games;
        },
      });
    },
  },
};
</script>

<style lang="scss">
.#{$baseClass}-mobile-info-page {
  padding: 20px 15px;
  &-content {
    width: 100%;
    height: 200px;
    background: #006cf3;
    border-radius: 14px;
    display: flex;
    align-items: center;
    padding: 20px;
    &-banner {
      width: 160px;
      height: 160px;
      border-radius: 14px;
      border: 4px solid #ffffff;
      margin-right: 10px;
    }
    &-info {
      &-title {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 6px;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &-dec {
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
      }
      &-btn {
        margin: 0 auto;
        width: 132px;
        height: 36px;
        background: #fe5e8a;
        border-radius: 18px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 5px;
            animation: shake-bottom 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
      }
    }
  }
  &-ads {
    width: 100%;
    height: 280px;
    // background: #9b9b9b;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
